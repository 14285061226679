import Button, { KIND } from "../components/Button/Button"
import { Col, Layout, Row } from "../components/Layout/Layout"

import { Link } from "gatsby"
import React from "react"
import Seo from "../components/Seo/Seo"

const NotFound = () => {
  return (
    <>
      <Seo />
      <Layout>
        <div style={{ marginTop: "6em" }}>
          <Row style={{ textAlign: "center" }}>
            <Col span={12}>
              <h1>You've taken a wrong turn, this page doesn't exist!</h1>
              <p>
                We can't find what you're looking for, but you can return to our
                home page below.
              </p>
              <Button kind={KIND.primary}>
                <Link to="/">Return Home</Link>
              </Button>
            </Col>
          </Row>
        </div>
      </Layout>
    </>
  )
}

export default NotFound
